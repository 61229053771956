define(['santa-components', 'lodash', 'coreUtils', 'socialCommon', 'components', 'skins', 'facebookLike/skins/skins.json', 'componentsCore'], function (santaComponents, _, coreUtils, socialCommon, components, skinsPackage, skinsJson, componentsCore) {
    'use strict';

    const BASE_URL = '//www.facebook.com/plugins/like.php?a=a';
    const LOCALES = {
        da: 'da_DK',
        de: 'de_DE',
        en: 'en_US',
        es: 'es_ES',
        fr: 'fr_FR',
        it: 'it_IT',
        ja: 'ja_JP',
        kr: 'ko_KR',
        nl: 'nl_NL',
        no: 'nn_NO',
        pl: 'pl_PL',
        pt: 'pt_BR',
        ru: 'ru_RU',
        sv: 'sv_SE',
        tr: 'tr_TR'
    };

    /*
     layout - there are three options.
     standard - displays social text to the right of the button and friends' profile photos below. Minimum width: 225 pixels. Minimum increases by 40px if action is 'recommend' by and increases by 60px if send is 'true'. Default width: 450 pixels. Height: 35 pixels (without photos) or 80 pixels (with photos).
     button_count - displays the total number of likes to the right of the button. Minimum width: 90 pixels. Default width: 90 pixels. Height: 20 pixels.
     box_count - displays the total number of likes above the button. Minimum width: 55 pixels. Default width: 55 pixels. Height: 65 pixels.
     */
    function getSizeAccordingToProperties(props, lang = 'en') {
        const DEFAULT_PROPERTIES = {
            layout: 'box_count',
            show_faces: 'box_count',
            action: 'like'
        };
        const mapWidthForEachLanguageButtonCountLike = {
            en: 77,
            es: 105,
            ru: 125,
            pt: 95,
            kr: 98,
            de: 114,
            da: 159,
            fr: 92,
            it: 114,
            ja: 104,
            nl: 150,
            no: 123,
            pl: 114,
            sv: 75,
            tr: 100
        };
        const mapWidthForEachLanguageButtonCountRecommend = {
            en: 121,
            es: 124,
            ru: 153,
            pt: 132,
            kr: 89,
            de: 116,
            da: 121,
            fr: 135,
            it: 121,
            ja: 104,
            nl: 151,
            no: 101,
            pl: 97,
            sv: 133,
            tr: 120
        };
        const mapWidthForEachLanguageBoxCountLike = {
            en: 51,
            es: 76,
            ru: 81,
            pt: 58,
            kr: 57,
            de: 83,
            da: 107,
            fr: 63,
            it: 72,
            ja: 72,
            nl: 89,
            no: 74,
            pl: 75,
            sv: 52,
            tr: 62
        };
        const mapWidthForEachLanguageBoxCountRecommend = {
            en: 95,
            es: 95,
            ru: 109,
            pt: 95,
            kr: 48,
            de: 85,
            da: 69,
            fr: 105,
            it: 78,
            ja: 72,
            nl: 90,
            no: 52,
            pl: 58,
            sv: 111,
            tr: 82
        };
        const FB_LIKE_SIZES_PRESETS = {
            standard: {
                like: {
                    w: 250,
                    h: 40
                },
                recommend: {
                    w: 290,
                    h: 40
                }
            },
            standard_showFaces: {
                like: {
                    w: 250,
                    h: 85
                },
                recommend: {
                    w: 290,
                    h: 85
                }
            },
            button_count: {
                like: {
                    w: mapWidthForEachLanguageButtonCountLike[lang],
                    h: 20
                },
                recommend: {
                    w: mapWidthForEachLanguageButtonCountRecommend[lang],
                    h: 20
                }
            },
            box_count: {
                like: {
                    w: mapWidthForEachLanguageBoxCountLike[lang],
                    h: 40
                },
                recommend: {
                    w: mapWidthForEachLanguageBoxCountRecommend[lang],
                    h: 40
                }
            },
            box_count_showFaces: {
                like: {
                    w: 85,
                    h: 65
                },
                recommend: {
                    w: 125,
                    h: 65
                }
            },
            button_count_showFaces: {
                like: {
                    w: 137,
                    h: 20
                },
                recommend: {
                    w: 137,
                    h: 20
                }
            }
        };

        let selectedLayout = props ? props.layout : DEFAULT_PROPERTIES.layout;
        selectedLayout = props && props.show_faces ? `${selectedLayout}_showFaces` : selectedLayout;
        const action = props ? props.action : DEFAULT_PROPERTIES.action;
        return FB_LIKE_SIZES_PRESETS[selectedLayout][action];
    }

    const urlUtils = coreUtils.urlUtils;

    function getIFrameProperties(props, currentUrl) {
        return {
            'data-src': getIFrameSrc(props, currentUrl),
            frameBorder: '0',
            width: '100%',
            height: '100%',
            scrolling: 'no',
            overflow: 'hidden',
            allowtransparency: 'true',
            title: getIframeTitle(props),
            'aria-label': getIframeTitle(props)
        };
    }

    function getLanguage(propLang, userLanguage) {
        if (propLang === 'userLang') {
            return userLanguage;
        }
        return propLang;
    }

    function getLocale(propLang, userLanguage) {
        const lang = getLanguage(propLang, userLanguage);
        return LOCALES[lang] || LOCALES.en;
    }

    function getIFrameSrc(props, currentUrl) {
        const compProp = props.compProp;
        const params = {
            href: currentUrl,
            layout: compProp.layout,
            show_faces: compProp.show_faces,
            action: compProp.action,
            colorscheme: compProp.colorScheme,
            send: compProp.send,
            locale: getLocale(compProp.language, props.userLanguage)
        };
        return urlUtils.addProtocolIfMissing(`${BASE_URL}&${urlUtils.toQueryString(params)}`, 'https:');
    }

    function getIframeTitle(props) {
        const lang = getLanguage(props.compProp.language, props.cookie, props.currentUrl);
        return coreUtils.translationsLoader.getTranslation('component_label', lang, 'component_label_ facebookLikeTitle');
    }

    /**
     * @class components.facebookLike
     * @extends {core.skinBasedComp}
     */
    const facebookLike = {
        displayName: 'FacebookLike',
        mixins: [componentsCore.mixins.skinBasedComp, socialCommon.socialCompMixin],
        propTypes: {
            rootId: santaComponents.santaTypesDefinitions.Component.rootId,
            structure: santaComponents.santaTypesDefinitions.Component.structure.isRequired,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
            currentUrl: santaComponents.santaTypesDefinitions.currentUrl,
            userLanguage: santaComponents.santaTypesDefinitions.WixUserSantaTypes.userLanguage.isRequired
        },
        getSkinProperties() {
            const {w, h} = getSizeAccordingToProperties(this.props.compProp, getLanguage(this.props.compProp.language, this.props.userLanguage));
            const iframeProperties = getIFrameProperties(this.props, this.getSocialUrl(this.props.rootId === 'masterPage'));
            return {
                '': {
                    style: {
                        width: `${w}px`,
                        height: `${h}px`
                    },
                    title: getIframeTitle(this.props),
                    'aria-label': getIframeTitle(this.props),
                    'data-src': iframeProperties['data-src'],
                    tagName: 'wix-iframe'
                },
                iframe: iframeProperties
            };
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.WFacebookLike', facebookLike);
    components.translationRequirementsChecker.registerCommonLanguageRequirement(
        'wysiwyg.viewer.components.WFacebookLike',
        (siteData, compInfo) => _.get(compInfo, ['properties', 'language']));

    skinsPackage.skinsMap.addBatch(skinsJson);

    return facebookLike;
});
